<template>
    <v-container fluid class="mb-5 mx-0 px-0">
        <v-row align="center" justify="center">
            <v-expansion-panels v-model="panel" multiple>
                <v-expansion-panel class="rounded-lg">
                    <v-expansion-panel-header class="font-weight-bold text-h6 py-0">
                        <v-row justify="start">
                            <v-col cols="12" md="2" align="start">
                                <v-icon color="orange">mdi-basketball</v-icon>
                                Φίλτρα
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="elevation-1 rounded-lg">
                        <v-row justify="center">
                            <v-col cols="5" align="center">
                                <v-select
                                    v-model="year"
                                    color="orange"
                                    item-color="orange"
                                    :items="['2022-2023','2021-2022','2020-2021']"
                                    return-object
                                />
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.mobile" cols="2">
                                <v-divider
                                    vertical
                                    color="orange"
                                    class="custom-divider"
                                />
                            </v-col>
                            <v-col cols="5" align="center">
                                <v-select
                                    v-model="phase"
                                    color="orange"
                                    item-color="orange"
                                    :items="[{title:'Προκριματικά', value:'placements'}, {title:'Regular Season', value:'regular_season'}, {title:'PlayOffs', value:'playoffs'}]"
                                    item-text="title"
                                    item-value="value"
                                />
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.mobile" cols="2">
                                <v-divider
                                    vertical
                                    color="orange"
                                    class="custom-divider"
                                />
                            </v-col>
                            <v-col cols="5" align="center">
                                <v-select
                                    v-model="round"
                                    color="orange"
                                    item-color="orange"
                                    :items="['Round 1','Round 2','Round 3','Round 4', ,'Round 5']"
                                    return-object
                                />
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.mobile" cols="2">
                                <v-divider
                                    vertical
                                    color="orange"
                                    class="custom-divider"
                                />
                            </v-col>
                            <v-col cols="5" align="center">
                                <v-select
                                    v-model="team"
                                    color="orange"
                                    item-color="orange"
                                    :items="['Veterans BC','Moschato Bulls','Blazers BC','West Side', ,'Piranhas BC']"
                                    return-object
                                />
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                panel: [0],
                team: 'Veterans BC',
                year: '2022-2023',
                phase: 'Regular Season',
                round: 'Round 1'
            }
        }
    }
</script>

<style>
.v-select__selection--comma {
    font-weight: 600 !important;
}

.v-select {
    font-family: Ubuntu, Arial, sans-serif !important;
    font-weight: 600;
}

.v-list-item {
    font-family: Ubuntu, Arial, sans-serif !important;
    font-weight: 600;
}
</style>

<style scoped>
.text-h6 {
    font-size: 1.25rem !important;
    letter-spacing: 0.0125em !important;
    line-height: 2rem;
    font-family: Arial, sans-serif !important;
}

.custom-divider {
    border-width: 1px;
}

</style>
