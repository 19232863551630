<template>
    <v-container fluid class="mx-0 px-0">
        <v-row justify="center">
            <v-col cols="12" md="3" align="start">
                <v-card class="mt-5 px-5 py-2 rounded-lg custom-header">
                    <v-row no-gutters>
                        <v-col cols="12" align="start">
                            <v-icon large class="custom-orange-text"> mdi-information-outline </v-icon>
                            <span class="custom-orange-text">Team Info</span>
                        </v-col>
                    </v-row>
                    <div>
                        <v-icon large class="custom-orange-text"> mdi-human-male-height </v-icon>
                        <span class="text-h5 secondary-font"> {{ `Avg. Height: ${getAvgHeight().toFixed(2)}m` }} </span>
                    </div>
                    <div>
                        <v-icon large class="custom-orange-text"> mdi-human-white-cane </v-icon>
                        <span class="text-h5 secondary-font"> {{ `Avg. Age: ${getAvgAge().toFixed(0)}` }} </span>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <span
            v-for="(position, index) in positions"
            :key="index"
        >
            <v-row>
                <v-col cols="12" align="start" class="my-10">
                    <span class="custom-header px-5 py-2">
                        <span class="custom-orange-text">{{ position }}</span>
                    </span>
                </v-col>
            </v-row>
            <v-row
                justify="start"
                align="center"
            >
                <v-col
                    v-for="(item, index) in players.filter(player => player.position === position)"
                    :key="index"
                    cols="12"
                    md="3"
                >
                    <v-card
                        class="rounded-lg py-1 px-1"
                        :style="`background: linear-gradient(110deg, ${color ? color : 'orange'} 30%, white 30%);`"
                        height="90px"
                        hover
                        @click="$router.push({path:`/Player/${item.id}`})"
                    >
                        <v-row class="py-2 black--text secondary-font font-weight-bold" align="center">
                            <v-col cols="3">
                                <v-img
                                    v-if="item.image"
                                    :src="`${assetsUrl}/${item.image}`"
                                    height="70px"
                                    contain
                                    @error="item.image = null"
                                />
                                <v-icon v-else x-large color="white">
                                    mdi-account
                                </v-icon>
                            </v-col>
                            <v-col cols="6" align="center">
                                <div style="overflow-wrap: normal;">
                                    {{ `${item.name} ${item.surname}` }}
                                </div>
                            </v-col>
                            <v-col cols="3" align="end">
                                <span>
                                    <v-icon class="hashtag">
                                        mdi-pound
                                    </v-icon>
                                </span>
                                <span class="number">{{ item.jersey }}</span>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </span>
    </v-container>
</template>

<script>
    export default {
        props: {
            players: {
                type: Array,
                default: null
            },
            color: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                positions: ['guard', 'forward', 'center'],
                assetsUrl: process.env.VUE_APP_GOOGLE_STORAGE_API
            }
        },
        methods: {
            getAvgHeight() {
                let sum = 0
                let count = 0
                this.players.forEach(player => {
                    if (player.active) {
                        sum += player.height
                        count += 1
                    }
                })
                return sum / count
            },
            getAvgAge() {
                let sum = 0
                let count = 0
                this.players.forEach(player => {
                    if (player.active && player.birthday) {
                        const dateParts = player.birthday.split('/')
                        const from = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`)
                        const today = new Date()
                        sum += player.active ? today.getFullYear() - from.getFullYear() : 0
                        count += 1
                    }
                })
                return sum / count
            }
        }
    }
</script>

<style scoped>
.custom-header {
    font-weight: 600;
    font-size: 2rem;
    border-radius: 10px;
    background-color: white !important;
}

.hashtag {
    color: #7575786c;
}

.number {
    font-size: 2rem;
}

.custom-divider {
    border-width: 1px;
    height: 70px;
}
</style>
